/* * @Author: 曹俊杰 * @Date: 2020-09-08 16:38:07 * @Module:资质查询
certification */
<template>
  <div class="certification">
    <div
      class="certification-header certification-index-header"
      style="display:flex;justify-content: center;align-items:center;flex-direction:column"
    >
      <!-- <resultheader /> -->
      <div>
        <div class="certification-index-header-title">身份查询 安全合作</div>
        <div class="certification-index-header-native">
          <div
            v-for="item in nativeList"
            :key="item.key"
            class="pointer"
            :class="
              native == item.key
                ? 'certification-index-header-native-ac'
                : 'certification-index-header-native-df'
            "
            @click="native = item.key"
          >
            {{ item.title }}
            <div
              class="certification-index-header-native-triangle"
              v-show="native == item.key"
            ></div>
          </div>
        </div>
        <div class="certification-index-header-search">
          <input
            @keyup.enter="searchClick"
            type="text"
            v-model.trim="keyWord"
            :placeholder="
              native == 'wechart' ? '请输入准确的微信号' : '请输入准确的电话号'
            "
          />
          <div
            class="certification-index-header-search-btn pointer"
            @click="searchClick"
          >
            <i class="iconfont iconsousuo" style="margin-right:7px;" />查一下
          </div>
        </div>
        <div class="certification-index-header-text">
          <img src="@/static/certificationImg/text_1.png" />
        </div>
      </div>
    </div>
    <!-- <div class="certification-content certification-index-content">
      <div class="certification-index-content-title">
        <p>PERMANENT ESTABLISHMENT</p>
        <p>常设机构</p>
      </div>
      <div class="certification-index-content-box">
        <div v-for="(item, index) in list"
             :key="index"
             class="certification-index-content-item pointer">
       
          <div class="certification-index-content-item-t">
            <div>
              <span style="font-weight: 500;">{{ item.name }}</span>
              <span class="iconfont iconanquan1"
                    style="color:#4B9CFC;"></span>
            </div>
            <div :style="'background:' + colorList[$random(1, 3)]">
              {{ item.name.substring(0, 4) }}
            </div>
          </div>
          <div class="certification-index-content-item-b">
            <div>信息更新日期：</div>
            <div>{{ item.date }}</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="certification-footer certification-index-footer">
      豫ICP备19039737号 中能魔力（河南）网络科技有限公司
    </div>
  </div>
</template>
<script>
// import resultheader from "./header";
import list from "./company.list";
export default {
  data() {
    return {
      native: "wechart",
      keyWord: "",
      nativeList: [
        {
          title: "查微信",
          key: "wechart"
        },
        {
          title: "查电话",
          key: "phone"
        }
      ],
      colorList: ["#2990F9", "#DF2A29", "#FCC12D"],
      list
    };
  },
  // components: { resultheader },
  mounted() {},
  methods: {
    searchClick() {
      const { native: type, keyWord, native } = this;
      if (this.keyWord)
        this.$router.push(
          `/certification-result?type=${type}&keyWord=${keyWord}`
        );
      else
        this.$message({
          message: `请输入要查询的${native == "wechart" ? "微信号" : "电话号"}`,
          type: "warning"
        });
    },
    detailClick() {
      this.$router.push("/certification-detail");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
.certification-index-header {
  height: calc(100vh - 80px);
  background: url(../../static/certificationImg/bg1.png) no-repeat center center;
  background-size: 100% 100%;
  overflow: hidden;
  &-title {
    font-weight: 500;
    color: #ffffff;
    line-height: 75px;
    letter-spacing: 10px;
    font-size: 50px;
    text-align: center;
    // margin-top: 130px;
  }
  &-native {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    &-df,
    &-ac {
      position: relative;
      font-size: 16px;
      line-height: 28px;
      padding: 0 12px;
      border-radius: 2px;
      margin: 0 17px;
    }
    &-df {
      color: #fff;
    }
    &-ac {
      background: #fff;
      color: #333333;
    }
    &-triangle {
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-top-color: #fff;
      position: absolute;
      left: 0px;
      right: 0px;
      margin: auto;
    }
  }
  &-search {
    width: 870px;
    background: #fff;
    height: 50px;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    input {
      flex: 1;
      padding: 0 20px;
    }
    &-btn {
      height: 50px;
      width: 100px;
      background: #fcc12d;
      line-height: 50px;
      text-align: center;
      color: #fff;
    }
  }
  &-text {
    img {
      width: 564px;
    }
    margin: 0 auto;
    margin-top: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.certification-index-content {
  &-title {
    height: 160px;
    text-align: center;
    position: relative;
  }
  p:first-child {
    font-size: 28px;
    color: rgba(51, 51, 51, 0.2);
    position: absolute;
    top: 70px;
    left: 0px;
    right: 0px;
    margin: auto;
    font-weight: 500;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  }
  p:last-child {
    color: #333333;
    font-weight: 500;
    font-size: 28px;
    position: absolute;
    top: 85px;
    left: 0px;
    right: 0px;
    margin: auto;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  }
  &-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &-item {
    width: 350px;
    height: 170px;
    margin-bottom: 40px;
    background: url(../../static/certificationImg/bg_2.png) no-repeat center
      center;
    background-size: 100% 100%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    // padding: 33px 43px 28px;
    padding: 33px 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-t,
    &-b {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-t {
      div:first-child {
        width: 164px;
        font-size: 16px;
        line-height: 24px;
      }
      div:last-child {
        width: 54px;
        height: 54px;
        background: #2990f9;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        color: #fff;
        box-sizing: border-box;
        font-size: 20px;
        text-align: center;
        display: flex;
        align-items: center;
      }
    }
    &-b {
      font-size: 13px;
      color: #666666;
      text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      text-align: right;
    }
  }
  &-item:nth-child(3n-1) {
    margin-left: 95px;
    margin-right: 95px;
  }
}
.certification-index-footer {
  background: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 11px;
  color: #666666;
}
</style>
